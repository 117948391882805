import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";

require("../css/staticPages.css");

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <HeaderLight pathname="/privacy-policy" />
                <div className="container NC-vision1 ">
                    <div className="row mar-top-btm">
                        <div className="col-sm-12 pad0 ">
                            <div> <h3 className="bannerHeading">Privacy Policy</h3> </div>
                            <div className="row eachRow">
                                {/* <h5 className="subheading">Cancellation by Site Customer{" "}</h5> */}
                                <p><span className="pleaseNote">Native Circle</span> respects your privacy. This Privacy Policy provides succinctly the manner your data is collected and used by Native Circle on the Site. As a visitor to the Site/ Customer you are advised to please read the Privacy Policy carefully. By accessing the services provided by the Site you agree to the collection and use of your data by Native Circle in the manner provided in this Privacy Policy.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">WHAT INFORMATION IS, OR MAY BE, COLLECTED FORM YOU?{" "}</h5>
                                <p>As part of the registration process on the Site, Native Circle may collect the following personally identifiable information about you: Name including first and last name, alternate email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">HOW DO WE COLLECT THE INFORMATION ?{" "}</h5>
                                <p>Native Circle will collect personally identifiable information about you only as part of a voluntary registration process, on-line survey or any combination thereof. The Site may contain links to other Web sites.  Native Circle is not responsible for the privacy practices of such Web sites which it does not own, manage or control. The Site and third-party vendors, including Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on someone's past visits to the Site.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">HOW IS INFORMATION USED ?{" "}</h5>
                                <p>Native Circle will use your personal information to provide personalized features to you on the Site and to provide for promotional offers to you through the Site and other channels. Native Circle will also provide this information to its business associates and partners to get in touch with you when necessary to provide the services requested by you. Native Circle will use this information to preserve transaction history as governed by existing law or policy. Native Circle may also use contact information internally to direct its efforts for product improvement, to contact you as a survey respondent, to notify you if you win any contest; and to send you promotional materials from its contest sponsors or advertisers. Native Circle will also use this information to serve various promotional and advertising materials to you via display advertisements through the Google Ad network on third party websites. You can opt out of Google Analytics for Display Advertising and customize Google Display network ads using the Ads Preferences Manager.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">WITH WHOM YOUR INFORMATION WILL BE SHARED?{" "}</h5>
                                <p>Native Circle will not use your financial information for any purpose other than to complete a transaction with you. Native Circle does not rent, sell or share your personal information and will not disclose any of your personally identifiable information to third parties. In cases where it has your permission to provide products or services you've requested and such information is necessary to provide these products or services the information may be shared with Native Circle’s business associates and partners. In addition Native Circle may use this information for promotional offers, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the Site’s terms of use or to defend against legal claims; special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">WHAT CHOICE ARE AVAILABLE TO YOU REGARDING COLLECTION, USE AND DISTRIBUTION OF YOUR INFORMATION ?{" "}</h5>
                                <p>To protect against the loss, misuse and alteration of the information under its control, Native Circle has in place appropriate physical, electronic and managerial procedures. For example, Native Circle servers are accessible only to authorized personnel and your information is shared with employees and authorized personnel on a need to know basis to complete the transaction and to provide the services requested by you. Although Native Circle will endeavor to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using this site, you agree that Native Circle will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">HOW CAN YOU CORRECT INACCURACIES IN THE INFORMATION ?{" "}</h5>
                                <p>To correct or update any information you have provided, the Site allows you to do it online. In the event of loss of access details you can send an email to: store email id</p>
                                <p><span className="pleaseNote">Policy updates:</span> Native Circle reserves the right to change or update this policy at any time. Such changes shall be effective immediately upon posting to the Site.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">Trademarks{" "}</h5>
                                <p><span className="pleaseNote">Protected Marks:</span> www.nativecircle.in, fonts, design, Native Circle products, and other marks indicated on this website are registered trademarks.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">Product Promotion SMS & Email{" "}</h5>
                                <p> Native Circle may send you E-mails / SMS or any other communication relating to the Products Information/Promotional activities/Offers and other Programs, from time to time, on your registered contact details. In case of any objection with such E-mails/SMS, do mail us request on info@nativecircle.com to stop email/SMS in future.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicy;
